<template>
    <MainPaperFormView :Type="'Sent'" :Status="1" :fullTable=true :canWatch=false > </MainPaperFormView>
    <LogoutButton />
</template>

<script>
import MainPaperFormView from '@/components/MainPaperFormView';
import isMobile from '@/mixins/device'
import LogoutButton from '@/components/LogoutButton'


export default {
    name: 'SentBox',
    components: {
        MainPaperFormView,
        LogoutButton,
    },
    created() {
    },
    beforeRouteEnter(route, from, next) {
        // if(from.name == "admin_login" && isMobile.isMobile()) {
        if(isMobile.isMobile()) {
            next(vm => vm.setSidebarText())
        } else {
            next()
        }
    },
    computed: {
        showSpinner: {
            get: function() {
                return this.$store.getters["gridDataStore/getShowSpinner"]
            }
        },
        // editableForm: {
        //     get: function() {
        //         return this.$store.getters["gridDataStore/isEditableForm"]
        //     },
        // }
    },
    data: function() {
        return {
            Loading: false,
            visible: true,
        }
    },
    methods: {
        async setSidebarText() {
            this.$store.commit("sidebarStore/setVisibleBottomSidebarText", this.$i18n.tc("sentBox.plzPC"))
            this.$store.commit("sidebarStore/setVisibleBottomSidebar", true)
        }
    }
}
</script>

